// jazzCashForm.js
export const submitJazzCashForm = (formData) => {
    const form = document.createElement('form');
    form.method = 'POST';
    //https://sandbox.jazzcash.com.pk/WalletLinkingPortal/wallet/LinkWallet
    form.action = window.location.href.includes('localhost') ?
        'https://sandbox.jazzcash.com.pk/WalletLinkingPortal/wallet/LinkWallet' :
        'https://payments.jazzcash.com.pk/WalletLinkingPortal/wallet/LinkWallet';

    // Create hidden input fields based on formData
    Object.keys(formData).forEach((key) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = formData[key];
        form.appendChild(input);
    });

    // Append the form to the body and submit it
    document.body.appendChild(form);
    
    form.submit();
    console.log(form, 'form')
};
