import React from 'react';
import './comingSoon.scss';

const ComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <img src='/images/NoTasks.png' alt="Coming Soon" className="coming-soon-image" />
            <p className="coming-soon-text">
                This plan is coming soon, we will notify you about it soon.
            </p>
        </div>
    );
};

export default ComingSoon;
