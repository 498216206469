
import React, { useState, useEffect } from 'react'
import PlanCard from '../../PolicyCover/planCard';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './Plans.scss'
import { useNavigate } from 'react-router-dom';
function Plans(selectedPolicy) {
    const [isProfileIncomplete, setIsProfileIncomplete] = useState(false)
    const selectedUserPolicy = selectedPolicy?.selectedPolicy
    const userData = JSON.parse(localStorage.getItem('user'))
    const stats = selectedPolicy?.stats
    const navigate = useNavigate();
    useEffect(() => {
        if(!userData?.first_name || !userData?.cnic || !userData?.email || !userData?.dob){
            setIsProfileIncomplete(true)
        }
    }, [userData?.cnic, userData?.dob, userData?.email, userData?.first_name])
    return (
        <div className="cancer-cover-container">
            <div className="left-component">
                <div className="plan-component">
                    <div className="cancer-cover">
                        <div className="cover-header">
                            <img src='/images/dashboard/ribbon.png' alt='cover' className="cancer-cover-image" />
                            <div className="cover-text">
                                <div className="text">
                                    <h3>{selectedUserPolicy?.title}</h3>
                                    <p>{selectedUserPolicy?.description}</p>
                                </div>
                                <span className="status">
                                    {selectedUserPolicy ? 'Active' : 'NOT ACTIVE YET'}
                                </span>
                            </div>
                        </div>
                    </div>
                    {isProfileIncomplete && <div className="profile-incomplete">
                        <div className="profile-incomplete-header">
                            <div className="profile-incomplete-cover-text">
                                <div className="profile-incomplete-cover-header">
                                    <span style={{ marginRight: '10px' }}><ReportProblemIcon style={{ color: 'orange' }} /></span>
                                    <span> Incomplete Profile Information</span>
                                </div>
                            </div>
                        </div>
                        <div className='profile-incomplete-text'>
                            To qualify for the cover in case you ever needed. Kindly provide the missing information.
                        </div>
                        <div>
                            <button className="profile-incomplete-button" onClick={() => navigate('/profile')}>Complete Profile</button>
                        </div>
                    </div>}
                    <PlanCard />
                </div>
            </div>
            <div className="right-component">
                <h2 className="header-text">Stats for this plan</h2>
                <div className="inner-content">
                    <div className="plan-stats">
                        <div className="stats-content">
                            <div className="stat-item">
                                <span>Active members</span>
                                <span>{stats?.activeMembers.toLocaleString()}</span>
                            </div>
                            <div className="stat-item">
                                <span>Members benefited</span>
                                <span>{stats?.membersBenefited.toLocaleString()}</span>
                            </div>
                            <div className="stat-item">
                                <span>Amount disbursed</span>
                                <span>Rs. {stats?.amountDisbursed.toLocaleString()}</span>
                            </div>
                            <div className="stat-item">
                                <span>Last month average contribution</span>
                                <span>Rs. {stats?.lastMonthContribution}</span>
                            </div>
                        </div>

                        {/* Add horizontal line here */}
                        <div className="divider"></div>

                        <div className="terms">
                            <span>Read <a href="https://ittehaad.com/faqs/#terms-and-conditions" target='blank'>Terms and Conditions</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Plans;
