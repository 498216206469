import React, { useState, useEffect } from "react";
import './Policy.scss';  // The CSS you provided
import { useAppDispatch, useAppSelector } from "../../../store";
import { deleteUserPlan, deleteUserPolicy, GetPlans } from "../../../features/api.slice";
import { allPlansData } from "../../../features/api.selector";
import AddNewPolicy from "./PolicyPopup/Popup";
import AddNewPlan from "./PlanPopup/Popup";
import { Backdrop, useMediaQuery, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';  // Pencil icon
import DeleteIcon from '@mui/icons-material/Delete';  // Trash can
import { useToast } from "../../../components/ToastNotification/ToastNotification";
import ConfirmationDialog from "../../../components/DialogBox/Confirmation";
const Policy = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [openPlanDialog, setOpenPlanDialog] = useState(false);
    const [policyPopup, setPolicyPopup] = useState(false);
    const [planPopup, setPlanPopup] = useState(false);
    const [planForEdit, setPlanForEdit] = useState('')
    const [tabForEdit, setTabForEdit] = useState('')
    const [tabForDelete, setTabForDelete] = useState('')
    const [showScrollbar, setShowScrollbar] = useState(false);
    const [planForDelete, setPlanForDelete] = useState('')
    const dispatch = useAppDispatch();
    const user = JSON.parse(localStorage.getItem('user'));
    const showToast = useToast()
    // Fetch plans when the component mounts
    useEffect(() => {
        dispatch(GetPlans());
    }, [dispatch]);

    // Get all plans from the Redux store
    const plans = useAppSelector(allPlansData);

    // Filter active plans
    const selectedPlanData = plans?.body?.response?.filter(item => item.status === 'active');

    // Handle policy creation popup
    const handleCreateCoverage = () => {
        setPolicyPopup(true);
    };

    // Handle closing popup
    const closeCoveragePopup = () => {
        setPolicyPopup(false);
        setPlanPopup(false);
        setPlanForEdit('')
        setPlanForDelete('')
        setTabForEdit('')
    };

    // Media query for responsiveness
    const isMobile = useMediaQuery('(max-width:600px)');

    // Get the selected plan based on activeTab
    const selectedPlan = selectedPlanData?.find(plan => plan.id === activeTab);
    function handleCreatePlan() {
        setPlanPopup(true)
    }
    function handleCoverageEdit(tab, event) {
        event.stopPropagation();
        setTabForEdit(tab)
        setPolicyPopup(true)
    }
    
    function openConfirmationDialog(tab, event) {
        event.stopPropagation();
        setOpenDialog(true)
        setTabForDelete(tab?.id)
    }
    function handleCoverageDelete() {
        const payload = {
            policyID: tabForDelete,
            showToast,
        }
        dispatch(deleteUserPolicy(payload))
    }
    function handleClose() {
        setOpenDialog(false)
        setOpenPlanDialog(false)
        setPlanForEdit('')
        setPlanForDelete('')
    }

    function updatePlan(coverage) {
        setPlanForEdit(coverage)
        setPlanPopup(true)
    }

    // Function to handle tab click and show scrollbar temporarily
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setShowScrollbar(true);
        setTimeout(() => setShowScrollbar(false), 1000); // Hide scrollbar after 1 second
    };
    
    function handleDeleteConfirmation(data){
        setPlanForDelete(data)
        setOpenPlanDialog(true)
    }
    function deletePlan() {
        const payload = {
            policyID: planForDelete?.id,
            showToast,
        }
        dispatch(deleteUserPlan(payload))
    }
    return (
        <div style={{ padding: '20px' }}>
            {/* Header with buttons aligned */}
            <div className="policy-header">
                <h2 className="header-title">Coverage</h2>
                <div className="button-group">
                    <button className="policy-button" onClick={handleCreatePlan}>Add Plan</button>
                    <button className="policy-button" onClick={handleCreateCoverage}>Add Cover</button>
                </div>
            </div>

            {/* Tab menu */}
            <div className={`tab-menu ${showScrollbar ? 'show-scrollbar' : ''}`}>
                {selectedPlanData?.map((tab) => (
                    <button
                        key={tab.id}
                        className={activeTab === tab?.id ? 'active' : ''}
                        onClick={() => handleTabClick(tab?.id)}
                    >
                        <div className="tab-content">
                            <span>{tab?.title}</span>
                            <div className="tab-icons">
                                {user.admin && <><IconButton aria-label="edit" size="xsmall" onClick={(event) => handleCoverageEdit(tab, event)}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                                    <IconButton aria-label="delete" size="xsmall" onClick={(event) => openConfirmationDialog(tab, event)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton></>}
                            </div>
                        </div>
                    </button>
                ))}
            </div>

            {/* Display selected plan details */}
            <div className="policy-list">
                {selectedPlan?.plans.length > 0 ? (
                    selectedPlan?.plans?.map((coverage, index) => (
                        <div className="card-container" key={index}>
                            <div className="card-header">
                                {coverage.title} - Rs {coverage.price}
                            </div>
                            <div className="card-actions">
                                {user.admin &&
                                    <><IconButton aria-label="edit" onClick={() => updatePlan(coverage)}>
                                        <EditIcon />
                                    </IconButton>
                                        <IconButton aria-label="delete" onClick={()=> handleDeleteConfirmation(coverage)}>
                                            <DeleteIcon />
                                        </IconButton></>
                                }
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No plans available.</p>
                )}
            </div>

            {/* Policy creation popup */}
            {policyPopup && (
                <Backdrop
                    open={policyPopup}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <AddNewPolicy
                        tabForEdit={tabForEdit}
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile
                                ? { width: '80%', height: 'auto', padding: '16px' }
                                : { height: '100%', width: '100%' }),
                        }}
                        onContinue={closeCoveragePopup}
                    />
                </Backdrop>
            )}
            {/* Policy creation popup */}
            {planPopup && (
                <Backdrop
                    open={planPopup}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <AddNewPlan
                        activeTab={activeTab}
                        planForEdit={planForEdit}
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile
                                ? { width: '80%', height: 'auto', padding: '16px' }
                                : { height: '100%', width: '100%' }),
                        }}
                        onContinue={closeCoveragePopup}
                    />
                </Backdrop>
            )}
            <ConfirmationDialog
                open={openDialog}
                onClose={handleClose}
                onConfirm={handleCoverageDelete}
                message="Are you sure you want to delete this coverage?"
            />
            <ConfirmationDialog
                open={openPlanDialog}
                onClose={handleClose}
                onConfirm={deletePlan}
                message="Are you sure you want to delete this plan?"
            />
        </div>
    );
};

export default Policy;
