import { call, put, takeEvery, delay } from "redux-saga/effects";
import { LoginUserApi, SignUpUserApi, validateUserApi, GetAllPlans, deletePolicy,  /* RefreshTokenApi, */ updateUserProfile, createPolicy, changeUserContact, updatePlan, changeUserPincode, createPlan, getUserProfile, updatePolicy, deletePlan, preparePayment, } from "../services/api";
import { callLogin, deleteUserPlan, loginData, callSignUp, signUpData, deleteUserPolicy, updateUserPlan, callValidateUser, createPolicyCover, GetPlans, AllPlansData, updateUser, updateContact, changePincode, createPlanForPolicy, getUser, updateUserPolicy, clearStates, prepareUserPayment } from "../features/api.slice";
import { submitJazzCashForm } from "../utils/paymentForm";
function* checkExpiryTime(action) {
  const { navigate, showToast } = action;
  while (true) {
    const expiryTime = localStorage.getItem('expiryTime'); // Get expiry time from local storage
    if (expiryTime && Date.now() >= expiryTime) {
      // yield call(refreshTokenSaga, { navigate,showToast });
      navigate('/');
      localStorage.clear('');
      yield call(clearStates())
      showToast('Session Expired. Please Login again', 3000);
    }
    yield delay(60000); // Check every minute (60000 ms)
  }
}

function* LoginSaga(action) {
  const { navigate, showToast, setIsLoading, setIsLoggedIn, ...payload } = action.payload;
  const response = yield call(LoginUserApi, payload);
  yield put(loginData(response));
  if (response.status_code === 200) {
    localStorage.setItem('token', response?.body?.access_token);  // Store token after login
    localStorage.setItem('refreshToken', response?.body?.refresh_token);
    const expiryTime = Date.now() + response?.body?.expires_in; // Assuming expires_in is in seconds
    localStorage.setItem('expiryTime', expiryTime); // Store expiry time
    yield delay(500);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    yield delay(300);
    if (response?.body?.user?.first_name === '') {
      navigate('/profile');
    }
    else if (response?.body?.user?.first_name !== '') {
      navigate('/dashboard');
    }
    setIsLoading(false);
    showToast('Successfully Logged In', 3000);
    yield call(checkExpiryTime, { navigate, showToast });
    setIsLoggedIn(true);
  }
  if (response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  }
  if (response.status_code === 403) {
    showToast('Incorrect Pincode', 3000);
  }
  if (response.error) {
    showToast(response.message, 3000);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* validateUserSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(validateUserApi, payload);
  yield put(signUpData(response));
  if (response.status_code === 200 || response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  } else {
    showToast(response?.body?.message?.phone, 3000);
    setIsLoading(false);
  }
}
function* SignUpSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(SignUpUserApi, payload);
  yield put(signUpData(response));
  if (response.status_code === 200) {
    if (response?.body?.user?.first_name === '') {
      navigate('/profile');
    }
    else if (response?.body?.user?.first_name !== '') {
      navigate('/dashboard');
    }
    showToast('Successfully Signed Up and logged In', 3000);
    localStorage.setItem('token', response?.body?.access_token);  // Store token after login
    localStorage.setItem('refreshToken', response?.body?.refresh_token);
    yield delay(500);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    setIsLoading(false);
  } if (response.status_code === 400) {
    navigate('/modal', { state: { signUpData: response, payload: payload } });
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* updateUserProfileSaga(action) {
  const { navigate, showToast, setIsLoading, ...payload } = action.payload;
  const response = yield call(updateUserProfile, payload);
  if (response.status_code === 200) {
    showToast('Profile Updated Successfully', 3000);
    yield delay(500);
    setIsLoading(false);
    yield call(getUserProfileSaga, { showToast, setIsLoading });
    navigate('/dashboard')
  }
  else if (response.status_code !== 200) {
    showToast(response?.body?.message, 3000);
    setIsLoading(false);
  }
  else {
    setIsLoading(false);
  }
}
function* GetAppPlansSaga(action) {
  const response = yield call(GetAllPlans);
  yield put(AllPlansData(response));
}
function* updateUserContactSaga(action) {
  const { navigate, showToast, setIsLoading, onBackToProfile, setShowOTPModal, ...payload } = action.payload;
  const response = yield call(changeUserContact, payload);
  if (response.status_code === 200) {
    if (response?.body?.codeRequired === false) {
      showToast('Contact Updated Successfully', 3000);
      setIsLoading(false);
      yield call(getUserProfileSaga, { showToast, setIsLoading });
      onBackToProfile();
    }
    else if (response?.body?.codeRequired === true) {
      setShowOTPModal(true);
      setIsLoading(false);
    }
  }
}
function* updateUserPincodeSaga(action) {
  const { navigate, showToast, setIsLoading, onContinue, ...payload } = action.payload;
  const response = yield call(changeUserPincode, payload);
  if (response.status_code === 204) {
    showToast('Pincode Updated Successfully', 3000);
    setIsLoading(false);
    onContinue();
  }
  else if (response.status_code !== 204) {
    showToast(response?.body?.message, 3000);
    setIsLoading(false);
  }
}
function* createPlanSaga(action) {
  const { showToast, setIsLoading, onContinue, planID, ...payload } = action.payload;
  const response = yield call(createPlan, payload, planID);
  if (response.status_code === 200) {
    showToast('Plan Created Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* updatePlanSaga(action) {
  const { showToast, setIsLoading, onContinue, planID, ...payload } = action.payload;
  const response = yield call(updatePlan, payload, planID);
  if (response.status_code === 200) {
    showToast('Plan updated Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* getUserProfileSaga(action) {
  const { showToast, setIsLoading } = action;
  const response = yield call(getUserProfile);
  if (response.status_code === 200) {
    localStorage.setItem('user', JSON.stringify(response?.body));
  }
  else if (response?.status_code === 401) {
    showToast('Error occured! Please Try again')
    setIsLoading(false)
  }
  else {
    setIsLoading(false);
  }
}
function* createPolicySaga(action) {
  const { navigate, showToast, setIsLoading, onContinue, ...payload } = action.payload;
  const response = yield call(createPolicy, payload);
  if (response.status_code === 200) {
    showToast('Policy Created Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* updatePolicySaga(action) {
  const { showToast, setIsLoading, onContinue, policyID, ...payload } = action.payload;
  const response = yield call(updatePolicy, payload, policyID);
  if (response.status_code === 200) {
    showToast('Policy Updated Successfully', 3000);
    setIsLoading(false);
    onContinue()
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    setIsLoading(false);
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deletePolicySaga(action) {
  const { showToast, policyID, ...payload } = action.payload;
  const response = yield call(deletePolicy, payload, policyID);
  if (response.status_code === 204) {
    showToast('Policy deleted Successfully', 3000);
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    showToast('Error occured! Please Try again', 3000)
  }
}
function* deletePlanSaga(action) {
  const { showToast, policyID, ...payload } = action.payload;
  const response = yield call(deletePlan, payload, policyID);
  if (response.status_code === 204) {
    showToast('Policy deleted Successfully', 3000);
    yield delay(500);
    yield call(GetAppPlansSaga);
  }
  else {
    showToast('Error occured! Please Try again', 3000)
  }
}
/* function* refreshTokenSaga(action) {
  try {
    const response = yield call(RefreshTokenApi);
    console.log(response,'response for refreshToken')
    if(response.status_code === 200){
      localStorage.setItem('token', response?.body?.access_token);  // Store token after login
      localStorage.setItem('refreshToken', response?.body?.refresh_token);
      const expiryTime = Date.now() + response?.body?.expires_in; // Assuming expires_in is in seconds
      localStorage.setItem('expiryTime', expiryTime); // Store expiry time
    }
  } catch (error) {
    // Handle failure
  }
} */
/* function* revokeTokenSaga(action) {
  try {
    const response = yield call(RevokeTokenApi, action.payload);
    console.log(response)
  } catch (error) {
    yield put(refreshTokenFailure(error.message)); // Handle failure
  }
} */
function* preparePaymentSaga(action) {
  const { showToast, navigate, setIsLoading, ...payload } = action.payload;
  const response = yield call(preparePayment, payload);
  if (response.status_code === 200 && response?.body?.phone) {
    const formData = {
      pp_MerchantID: "MC124341",
      pp_Password: "vz31e163zz",
      pp_MSISDN: response?.body?.phone,
      pp_RequestID: response?.body?.requestId,
      pp_ReturnURL: response?.body?.returnUrl,
      pp_SecureHash: response?.body?.secureHash,
    };
    submitJazzCashForm(formData)

    // setIsLoading(false);
  }
  else {
    showToast('Error occured! Please Try again', 3000)
  }
}
function* RootSaga() {
  yield takeEvery(callLogin.type, LoginSaga);
  // yield takeEvery(refreshTokenRequested.type, refreshTokenSaga);
  yield takeEvery(callValidateUser.type, validateUserSaga);
  yield takeEvery(callSignUp.type, SignUpSaga);
  yield takeEvery(GetPlans.type, GetAppPlansSaga);
  yield takeEvery(updateUser.type, updateUserProfileSaga);
  yield takeEvery(updateContact.type, updateUserContactSaga);
  yield takeEvery(changePincode.type, updateUserPincodeSaga);
  yield takeEvery(createPlanForPolicy.type, createPlanSaga);
  yield takeEvery(getUser.type, getUserProfileSaga);
  yield takeEvery(createPolicyCover.type, createPolicySaga)
  yield takeEvery(updateUserPolicy.type, updatePolicySaga)
  yield takeEvery(deleteUserPolicy.type, deletePolicySaga)
  yield takeEvery(updateUserPlan.type, updatePlanSaga)
  yield takeEvery(deleteUserPlan.type, deletePlanSaga)
  yield takeEvery(prepareUserPayment.type, preparePaymentSaga)
}

export default RootSaga;
