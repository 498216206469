import React, { useContext, useState, useEffect, useRef } from 'react';
import './profileDropdown.scss';
import { useNavigate } from 'react-router-dom';
import ProfileDropdownContext from '../../contexts/profileDropDown';
import { useToast } from '../ToastNotification/ToastNotification';
import { clearStates } from '../../features/api.slice';
import { useAppDispatch } from '../../store'; 
import PlanContext from '../../contexts/PlanContext';

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const { setIsOpen } = useContext(ProfileDropdownContext);
    const [isLoading, setIsLoading] = useState(false);
    const showToast = useToast();
    const dropdownRef = useRef(null); // To reference the dropdown

    const handleClick = () => {
        setIsOpen(false);
        navigate('/profile');
    };

    const userData = JSON.parse(localStorage.getItem('user')) || {};

    function getInitials(name) {
        const nameParts = name?.trim()?.split(' ')?.filter(part => part.length > 0);
        const initials = nameParts?.map(part => part[0].toUpperCase()).join('');
        return initials;
    }
    const {  setIsLoggedIn } = useContext(PlanContext);

    const dispatch = useAppDispatch();
    function logoutHandler() {
        setIsLoading(true);
        setTimeout(() => {
            localStorage.clear();
            navigate('/');
            setIsOpen(false);
            showToast('Logged out successfully', 3000);
            dispatch(clearStates())
            setIsLoggedIn(false)
        }, 1000);
    }

    function handleYourPlans() {
        setIsOpen(false);
        navigate('/user-plans');
    }

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false); // Close the dropdown
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setIsOpen]);

    return (
        <div className="dropdown-container" ref={dropdownRef}>
            <div className="dropdown-menu">
                <div className="dropdown-section">
                    <div className="avatar">{getInitials(userData?.first_name)}</div>
                    <div className="user-info">
                        <div className="user-name">{userData?.first_name || userData?.phone}</div>
                    </div>
                </div>
                <div className="dropdown-section">
                    <div className="user-email">{userData?.email}</div>
                </div>
                <button onClick={handleYourPlans} className="dropdown-item">
                    Your Plans
                </button>
                <button onClick={handleClick} className="dropdown-item">
                    View or edit profile
                </button>
                <div className="logout-container">
                    <button className={isLoading ? 'disabled-button dropdown-item logout' : "dropdown-item logout"} disabled={isLoading} onClick={logoutHandler}>
                        {isLoading ? 'Logging out' : 'Log out'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileDropdown;
