import axios from 'axios';

const baseUrl = "https://ittehaad.faastdemo.com/api";

// Function to get the current token from localStorage
export function getAuthToken() {
  return localStorage.getItem('token');
}

// Create an Axios instance
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Axios request interceptor to add the token only if it's available
api.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`; // Add Authorization header only if token is not null
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Axios interceptor to catch 401 errors and trigger token refresh
api.interceptors.response.use(response => response);

export async function LoginUserApi(payload) {
  try {
    const response = await api.post('/login', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function SignUpUserApi(payload) {
  const response = await api.post('/signup', payload);
  return response.data;
}

export async function validateUserApi(payload) {
  try {
    const response = await api.post('/signup', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}

export async function RefreshTokenApi() {
  const payload = {
    grant_type: "refresh_token",
    client_id: "2",
    client_secret: "OZHFQy9cKcL3fPifVF20ekdiKie9de2ab3UM9KDEq",
    refresh_token: localStorage.getItem('refreshToken'),
    scope: ""
  }
  const response = await api.post('/token/refresh', payload);
  return response.data;
}
export async function RevokeTokenApi(payload) {
  const response = await api.post('/token/revoke', payload);
  return response.data;
}

export async function GetAllPlans() {
  try {
    const response = await api.get('/plans');
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updateUserProfile(payload) {
  try {
    const response = await api.put('/user/profile', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function changeUserContact(payload) {
  try {
    const response = await api.post('/user/phone', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function changeUserPincode(payload) {
  try {
    const response = await api.put('/user/password', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function createPlan(payload, planID) {
  try {
    const response = await api.post(`/admin/policy/plan/${planID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updatePlan(payload, planID) {
  try {
    const response = await api.patch(`/admin/policy/plan/${planID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deletePlan(payload, policyID) {
  try {
    const response = await api.delete(`/admin/policy/plan/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function getUserProfile(payload) {
  const response = await api.get('/user/me', payload);
  return response.data;
}
export async function createPolicy(payload) {
  try {
    const response = await api.post('/admin/policy', payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function updatePolicy(payload, policyID) {
  try {
    const response = await api.patch(`/admin/policy/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function deletePolicy(payload, policyID) {
  try {
    const response = await api.delete(`/admin/policy/${policyID}`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export async function preparePayment(payload) {
  try {
    const response = await api.post(`/payment/prepare`, payload);
    return response.data;
  } catch (e) {
    return e.response.data;
  }
}
export default api;
