import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './leftComponent.scss';

import HealthDeclarationModal from '../Modals/healthDeclarationModal';
import PlanContext from '../../contexts/PlanContext';
import PlanSelector from './planSelector';
import PlanCard from './planCard';
import { useMediaQuery } from '@mui/material';
import PlanDetails from './planDetails';
import ComingSoon from '../ComingSoon/comingSoon';
const LeftComponent = (selectedPolicy) => {
    const { isPlanSelected } = useContext(PlanContext);
    const [openModal, setOpenModal] = useState(false);
    const [selectedUserPlan, setSelectedUserPlan] = useState({});
    const navigate = useNavigate();

    const handleOpenModal = (e) => {
        setSelectedUserPlan(e);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleContinue = () => {
        setOpenModal(false);
        navigate('/payment', { state: { selectedUserPlan } });
    };

    const isMobile = useMediaQuery('(max-width: 768px)');
    const planData = selectedPolicy?.selectedPolicy?.selectedPolicy
    const handleButtonClick = () => {
        navigate('/contribution', { state: { selectedPolicy: selectedPolicy } });
    }
   const plans =  (selectedPolicy?.selectedPolicy?.selectedPolicy?.plans)
    return (
        <div className="left-component">
            <div className="plan-component">
                {plans?.length > 0 && <div className="cancer-cover">
                    <div className="cover-header" style={{alignItems:'flex-start'}}>
                        <img src='/images/dashboard/ribbon.png' alt='cover' className="cancer-cover-image" />
                        <div className="cover-text">
                            <div className="text">
                                <h3>{planData?.title}</h3>
                                <p>{planData?.description}</p>
                            </div>
                            <span className="status">
                                {isPlanSelected ? 'Active' : 'NOT ACTIVE YET'}
                            </span>
                        </div>
                    </div>
                </div>}
                {isPlanSelected ? (
                    <PlanCard />
                ) : (
                    isMobile ? (
                        <div className="mobile-stats-component">
                            <h2 className="header-text">About this Cover</h2>
                            <div className="inner-content">
                                <PlanDetails />
                            </div>
                            <button className="continue-button" onClick={handleButtonClick}>
                                Continue with this plan
                            </button>
                        </div>
                    ) : 
                    plans?.length > 0 ?<PlanSelector handleOpenModal={handleOpenModal} selectedPolicy={selectedPolicy} /> :<ComingSoon/>
                    
                )}
            </div>
            <HealthDeclarationModal open={openModal} handleClose={handleCloseModal} handleContinue={handleContinue} />
        </div>
    );
};

export default LeftComponent;
