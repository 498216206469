import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './reviewDetails.scss';
import PlanContext from '../../contexts/PlanContext';
import { PAYMENT_METHODS } from '../../utils/constants';
import CompletedModal from '../../components/Modal/CompletedModal/completedModal';
import { Backdrop } from '@mui/material';
import { useAppDispatch } from '../../store';
import { useToast } from '../../components/ToastNotification/ToastNotification';
import { GetPlans } from '../../features/api.slice';
const ReviewDetails = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        dispatch(GetPlans())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const userSelectedPlan = location?.state?.userSelectedPlan;
    const [modalOpen, setModalOpen] = useState(false);
    const { paymentMethod/* , setIsPlanSelected */ } = useContext(PlanContext);
    const paymentMethodDetails = PAYMENT_METHODS.find((p) => p.name === paymentMethod);
    const dispatch = useAppDispatch()
    const handleModalSubmit = () => {
        // setIsPlanSelected(true);
        navigate('/user-plans');
    };
    const showToast = useToast();
    const handleDoneClick = () => {
        const payload = {
            title: userSelectedPlan.title,
            price: userSelectedPlan.price,
            recommended: userSelectedPlan.recommended,
            planId: userSelectedPlan.id,
            policyId: userSelectedPlan.policy_id,
            navigate, // Pass navigate
            showToast, // Pass showToast
            setIsLoading,
            setModalOpen
        }
        console.log(payload,'payload')
        setModalOpen(true);
    };

    return (
        <div className="review-details-container">
            <div className="review-plan">
                <div className="plan-info">
                    <img src={userSelectedPlan?.image} alt={userSelectedPlan?.title} className="plan-image" />
                    <div className="plan-details">
                        <h3>{userSelectedPlan?.title}</h3>
                        <span>upto <strong>{userSelectedPlan?.price} Rs</strong>/month</span>
                    </div>
                    <button className="change-plan-button" onClick={()=>navigate('/dashboard')}>
                        <img src='/images/dashboard/pen.png' alt="Change plan" />
                        Change plan
                    </button>
                </div>
                <div className="plan-notice">
                    <i className="icon-info"></i>
                    <p>
                    Kindly ensure that your wallet has at least {userSelectedPlan?.price} Rs for a monthly auto deduction on 1st of every month. Otherwise, eligibility for cancer cover will be suspended for 3 months.
                    </p>
                </div>
            </div>

            <div className="account-info">
                <h4>From</h4>
                <div className="account-details">
                    <img src={paymentMethodDetails?.icon} alt={paymentMethodDetails?.name} className="account-icon" />
                    <div>
                        <p className="account-name">My {paymentMethodDetails?.name}</p>
                        <p className="account-number">11257961</p>
                    </div>
                </div>
                <p className="subscription-note">
                    Note: Subscription starts from 1st October 2024. You will be eligible for treatment after 90 days from today.
                </p>
            </div>

            <button className="confirm-button" onClick={handleDoneClick}>{isLoading ? 'Creating plan...' : 'Confirm Subscription'}</button>
            <p className="terms-text">
                By clicking on Confirm Subscription you are agreeing to the{' '}
                <a href="https://ittehaad.com/faqs/#terms-and-conditions" target='blank'>Terms and Conditions</a>
            </p>
            {modalOpen && (
                <Backdrop
                    open={modalOpen}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                >
                    <CompletedModal
                        title="Registration Complete"
                        message="Thank you for becoming a member and supporting ITTEHAAD."
                        btnText="Continue to dashboard"
                        onClickHandler={handleModalSubmit}
                    />
                </Backdrop>
            )}
        </div>
    );
};

export default ReviewDetails;
