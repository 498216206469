import React, { useState, useRef } from 'react';
import './singleDigitInput.scss';

const SingleDigitInputs = ({ onChange, showPassword }) => {
  const [digits, setDigits] = useState(['', '', '', '']); // State to store the values of the inputs
  const inputRefs = useRef([]); // Ref array to store the input references

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) { // Only allow digits
      const newDigits = [...digits];
      newDigits[index] = value;
      setDigits(newDigits);

      // Automatically focus the next input if available
      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      // Call onChange when all fields are filled
      if (newDigits.every((digit) => digit !== '')) {
        if (typeof onChange === 'function') {
          onChange(newDigits.join('')); // Send the combined 4-digit code to the parent
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    const value = e.target.value;

    // Handle backspace
    if (e.key === 'Backspace' && value === '' && index > 0) {
      inputRefs.current[index - 1].focus(); // Move to the previous input
      const newDigits = [...digits];
      newDigits[index - 1] = ''; // Clear the previous input's value
      setDigits(newDigits); // Update state
    }
  };

  return (
    <div className="otp-inputs">
      {digits?.map((digit, index) => (
        <input
          key={index}
          type={showPassword ? 'text' : 'password'} // Toggle between text and password input type
          maxLength="1"
          inputMode="numeric"
          className="otp-input"
          value={digit}
          placeholder="•"
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)} // Add the keydown handler
          ref={(el) => (inputRefs.current[index] = el)} // Assign each input a ref
        />
      ))}
    </div>
  );
};

export default SingleDigitInputs;
