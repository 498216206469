import React from 'react';
import './CompletedModal.scss';

const CompletedModal = ({ title, message, btnText, onClickHandler }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="modal-title">{title}</p>
        <p className="modal-message">{message}</p>
        <button className="modal-button" onClick={onClickHandler} >{btnText}</button>
      </div>
    </div>
  );
};

export default CompletedModal;
