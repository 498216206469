import React, { createContext, useState } from 'react';

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
    const [isPlanSelected, setIsPlanSelected] = useState(false);
    const [planName, setPlanName] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('JazzCash');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    return (
        <PlanContext.Provider value={{ isPlanSelected, setIsPlanSelected, planName, setPlanName, paymentMethod, setPaymentMethod,setIsLoggedIn,isLoggedIn }}>
            {children}
        </PlanContext.Provider>
    );
};

export default PlanContext;
