import React, { useContext, useState } from 'react';
import './planCard.scss';
import { useNavigate } from 'react-router-dom';
import PlanContext from '../../contexts/PlanContext';
import UnsubscribeModal from '../Modals/unsubscribeModal';
import SuccessfulUnsubscriptionModal from '../Modals/successfulUnsubscriptionModal';
import { useAppSelector } from '../../store';
import { allPlansData } from '../../features/api.selector';

const PlanCard = () => {
    const { planName, setIsPlanSelected } = useContext(PlanContext);
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessfulUnsubscriptionModalOpen, setIsSuccessfulUnsubscriptionModalOpen] = useState(false);
    const allPlans = useAppSelector(allPlansData)
    const planDetails = allPlans?.body?.response?.find((p) => p.title === planName);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleSuccessfulUnubscriptionCloseModal = () => {
        setIsPlanSelected(false);
        setIsSuccessfulUnsubscriptionModalOpen(false);
    };
    const handleConfirmModal = () => {
        setIsModalOpen(false);
        setIsSuccessfulUnsubscriptionModalOpen(true);
    };
    return (
        <div className="plan-card">
            <div className="plan-card-header">
                <div className="plan-info">
                    <img src={planDetails?.image} alt={planDetails?.name} className="plan-avatar" />
                    <div className="plan-details">
                        <span className="plan-name">{planDetails?.name}</span>
                    </div>
                </div>
                <div className="plan-price-info">
                    <span className="plan-price">upto <strong>{planDetails?.price}</strong> Rs/month</span>
                    <button className="change-plan" onClick={()=>navigate('/dashboard')}>
                        <img src='/images/dashboard/pen.png' alt="Change plan" className="change-icon" />
                        Change plan
                    </button>
                </div>
            </div>

            <div className="plan-notice">
                <i className="icon-info"></i>
                <p>
                    Kindly ensure that your wallet has at least {planDetails?.price} Rs for a monthly auto deduction on 1st of every month. Otherwise, eligibility for cancer cover will be suspended for 3 months.
                </p>
            </div>

            <button className="unsubscribe-button" onClick={handleOpenModal}>
                Unsubscribe from the plan
            </button>
            <UnsubscribeModal open={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirmModal} />
            <SuccessfulUnsubscriptionModal open={isSuccessfulUnsubscriptionModalOpen} onClose={handleSuccessfulUnubscriptionCloseModal} />
        </div>
    );
};

export default PlanCard;
