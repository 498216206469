import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleAndLogo from '../../TitleAndLogo/titleAndLogo';
import SingleDigitInputs from '../SingleDigitInput/singleDigitInput';
import { useAppDispatch } from '../../../store';
import { callLogin, callSignUp } from '../../../features/api.slice'
import { useToast } from '../../ToastNotification/ToastNotification';
import Visibility from '@mui/icons-material/Visibility';  // Import visibility icon
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 
import { useContext } from 'react';
import PlanContext from '../../../contexts/PlanContext'; 
const PincodeModal = (data) => {
  const navigate = useNavigate();
  const [pin, setPin] = useState('')
  const [newPin, setNewPin] = useState('')
  const [repeatNewPin, setRepeatNewPin] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false); // Added state for toggling visibility
  const mobileNumber = data?.data?.payload?.phone
  const referralCode = data?.data?.payload?.referral_code
  const userExists = data?.data?.signUpData?.body?.userExists
  const dispatch = useAppDispatch()

  async function handleOtpChange(e) {
    await setPin(e)
  }

  function handleNewPinChange(e) {
    setNewPin(e)
  }

  function handleRepeatNewPinChange(e) {
    setRepeatNewPin(e)
  }

  const showToast = useToast();

  useEffect(() => {
    if (pin !== '') {
      handleContinue()
    }
    if (newPin && repeatNewPin && newPin !== repeatNewPin) {
      showToast('Pincode does not match', 3000)
    }
    if (newPin && repeatNewPin && newPin === repeatNewPin) {
      handleContinue()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin, newPin, repeatNewPin])
  const {  setIsLoggedIn } = useContext(PlanContext);

  async function handleContinue() {
    setIsLoading(true)
    if (userExists) {
      const payload = {
        grant_type: "password",
        client_id: "2",
        client_secret: "OZHFQy9cKcL3fPifVF20ekdiKie9de2ab3UM9KDEq",
        username: mobileNumber,
        password: pin,
        scope: "",
        navigate,
        showToast,
        setIsLoading,
        setIsLoggedIn
        
      }
      await dispatch(callLogin(payload))
    }
    else {
      const payload = {
        grant_type: "password",
        client_id: "2",
        client_secret: "OZHFQy9cKcL3fPifVF20ekdiKie9de2ab3UM9KDEq",
        phone: mobileNumber,
        password: newPin,
        code: '',
        validate: 0,
        referral_code: referralCode,
        navigate,
        showToast,
        setIsLoading,
      }
      await dispatch(callSignUp(payload))
    }
  };

  return (
    <div className="otp-modal-wrapper">
      <div className="otp-modal">
        <div className="title-logo-container">
          <TitleAndLogo logoColor="pink" />
        </div>
        {userExists ? (
          <>
            <h2>Enter pincode</h2>
            <p className='d-flex justify-space-between'>
              Please enter your pin
              <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <VisibilityOff fontSize='small' style={{cursor:'pointer'}}/> : <Visibility fontSize='small' style={{cursor:'pointer'}}/>}
              </span>
            </p>
            <SingleDigitInputs onChange={handleOtpChange} showPassword={showPassword} />
          </>
        ) : (
          <>
            <h2>Set pincode</h2>
            <p className='d-flex justify-space-between'>
              Set a 4-digit pincode
              <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <VisibilityOff fontSize='small' style={{cursor:'pointer'}}/> : <Visibility fontSize='small' style={{cursor:'pointer'}}/>}
              </span>
            </p>
            <SingleDigitInputs onChange={handleNewPinChange} showPassword={showPassword} />

            <h2>Confirm pincode</h2>
            <p className='d-flex justify-space-between'>
              Re-enter the pincode
              
            </p>
            <SingleDigitInputs onChange={handleRepeatNewPinChange} showPassword={showPassword} />
          </>
        )}
        <button
          className={isLoading ? 'disabled-button continue-button' : "continue-button"}
          disabled={isLoading}
          onClick={handleContinue}
        >
          {isLoading ? 'Loading...' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default PincodeModal;
