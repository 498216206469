import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, Alert, useMediaQuery, Backdrop } from '@mui/material';
import { ModeEditOutlineOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './ProfilePage.scss';
import ChangeNumber from './ChangeNumber';
import { useToast } from '../../components/ToastNotification/ToastNotification';
import { useAppDispatch } from '../../store';
import { updateUser } from '../../features/api.slice';
import ChangePincodeModal from '../../components/Modal/PinCode/changePincode';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import locale if needed

// Optionally, you can import plugins for dayjs if needed for formatting
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useNavigate } from 'react-router-dom';
dayjs.extend(advancedFormat);
const ProfilePage = () => {
    const [isChangingNumber, setIsChangingNumber] = useState(false);
    const showToast = useToast();
    const userData = JSON.parse(localStorage.getItem('user'));
    const [isLoading, setIsLoading] = useState(false);
    // save values on refresh or navigation
    const [nameValue, setNameValue] = useState(userData?.first_name || '');
    const [cnicValue, setCnicValue] = useState(userData?.cnic || '');
    const [emailValue, setEmailValue] = useState(userData?.email || '');
    const maxDate = dayjs()?.subtract(15, 'year');
    const [dobValue, setDobValue] = useState(dayjs(userData?.dob || ''));
    const [showPinChangeModal, setShowPinChangeModal] = useState(false);
    const dispatch = useAppDispatch()
    const [invalidEmailInput, setInvalidEmailInput] = useState(false);
    const [emailHelperText, setEmailHelperText] = useState('');
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleChangeNumberClick = () => {
        setIsChangingNumber(true);
    };

    const handleBackToProfile = () => {
        setIsChangingNumber(false);
    };

    const handleNameChange = (e) => {
        setNameValue(e.target.value);
    };
    const handleCnicChange = (event) => {
        let value = event.target.value.replace(/\D/g, ""); // Remove all non-numeric characters
        // Add dashes at the correct positions
        if (value.length > 5) {
            value = `${value.slice(0, 5)}-${value.slice(5)}`;
        }
        if (value.length > 13) {
            value = `${value.slice(0, 13)}-${value.slice(13, 14)}`;
        }
        // Update state
        setCnicValue(value);
    };

    const handleEmailChange = (e) => {
        const newValue = e.target.value
        setEmailValue(newValue);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(newValue)) {
            setInvalidEmailInput(false);
            setEmailHelperText('');
        } else {
            setInvalidEmailInput(true);
            setEmailHelperText('Invalid email address.');
        }
    };
    const handleDateChange = (newValue) => {
        setDobValue(newValue);
    };
    function isValidCnic(cnic) {
        const expectedLength = 15;
        return cnic?.length === expectedLength;
    }
    function updateUserProfileInfo() {
        if (nameValue === '') {
            showToast('Please enter your name', 3000);
            return;
        }
        if (cnicValue === '') {
            showToast('Please enter yourCNIC', 3000);
            return;
        }
        if (!isValidCnic(cnicValue)) {
            showToast('Please enter a valid CNIC', 3000);
            return
        }
        if (emailValue === '') {
            showToast('Please enter your email', 3000);
            return;
        }
        if (dobValue === '') {
            showToast('Please enter your date of birth', 3000);
            return;
        }
        setIsLoading(true)
        const payload = {
            first_name: nameValue,
            email: emailValue,
            cnic: cnicValue,
            dob: new Date(dobValue).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }).split('/').reverse().join('-'),
            showToast,
            navigate,
            setIsLoading,
        }
        dispatch(updateUser(payload))
    }
    function changePin() {
        setShowPinChangeModal(true);
    }

    const handleClosePinCodeModal = () => {
        setShowPinChangeModal(false);
    };
    const datePlaceHolder = dayjs(maxDate).format('DD-MM-YYYY');

    return (
        <>
            {isChangingNumber ? (
                <ChangeNumber onBackToProfile={handleBackToProfile} />
            ) : (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box className="profile-container">

                        <h2 variant="h2" className="profile-title">
                            Your Profile
                        </h2>

                        {isMobile && (
                            <Alert
                                severity="warning"
                                sx={{
                                    backgroundColor: '#FBF8E2',
                                    height: 'fit-content',
                                    border: '2px solid #FFF5AC',
                                    width: '90%',
                                    marginBottom: '1rem',
                                    marginTop: '1rem',
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    lineHeight: '23.1px',
                                    textAlign: 'left',
                                }}

                            >
                                To be eligible for cancer cover please enter your CNIC details. Accurate information is crucial for appropriate treatment cover.
                            </Alert>
                        )}

                        <Box className="profile-content">
                            <Paper elevation={0} className="profile-paper">
                                <Box className="mobile-number-container">
                                    <Box>
                                        <Typography className="field-label">Mobile number</Typography>
                                        <Typography className="mobile-number">{userData?.phone}</Typography>
                                    </Box>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            textTransform: 'none',
                                            fontWeight: 'normal',
                                            padding: {
                                                xs: '4px 8px',    // Extra small screens (e.g. phones)
                                                sm: '6px 12px',   // Small screens (e.g. tablets)
                                                md: '8px 16px',   // Medium screens (e.g. laptops)
                                            },
                                            fontSize: {
                                                xs: '0.75rem',    // Extra small screens
                                                sm: '0.875rem',   // Small screens
                                                md: '1rem',       // Medium and above
                                            },
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            borderRadius: '10px',
                                            backgroundColor: 'transparent',
                                            color: 'inherit',
                                            boxShadow: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: {
                                                xs: '2px',        // Small screens
                                                sm: '4px',        // Medium screens
                                            },
                                            marginTop: {
                                                xs: '10px',       // Extra small screens
                                                sm: '15px',       // Small screens
                                                md: '25px',       // Medium and above
                                            },
                                            height: {
                                                xs: '40px',       // Extra small screens
                                                sm: '30px', 
                                                md: '35px'
                                                      // Small screens and above
                                            },
                                            marginLeft: {
                                                xs: '5px',        // Extra small screens
                                                sm: '10px',       // Small screens and above
                                            },

                                            // Add breakpoints for large and extra large screens if needed
                                            '@media (min-width: 1200px)': {
                                                // Adjust styles for large screens
                                            },
                                        }}
                                        startIcon={<ModeEditOutlineOutlined />}
                                        onClick={handleChangeNumberClick}
                                    >
                                        Change
                                    </Button>

                                </Box>

                                <Box mb={2}>
                                    <Typography className="field-label">Full name</Typography>
                                    <TextField value={nameValue} onChange={handleNameChange} placeholder="Enter your full name" fullWidth />
                                </Box>

                                <Box mb={2}>
                                    <Typography className="field-label">CNIC</Typography>
                                    <TextField
                                        value={cnicValue}
                                        inputProps={{ maxLength: 15 }} // Increase maxLength to 15 to accommodate dashes
                                        onChange={handleCnicChange}
                                        placeholder="Enter your CNIC"
                                        fullWidth
                                    />
                                </Box>
                                <Box mb={2}>
                                    <Typography className="field-label">Date of Birth</Typography>
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        slotProps={{
                                            inputAdornment: {
                                                position: 'start',
                                            },
                                            textField: { placeholder: datePlaceHolder },
                                        }}
                                        maxDate={maxDate}
                                        value={dobValue}
                                        onChange={handleDateChange}
                                        disableMobile={true}
                                        sx={{ width: '100%' }}
                                    />
                                </Box>

                                <Box mb={2}>
                                    <Typography className="field-label">Email</Typography>
                                    <TextField
                                        error={invalidEmailInput}
                                        helperText={emailHelperText}
                                        value={emailValue}
                                        onChange={handleEmailChange}
                                        placeholder="Enter your email"
                                        fullWidth
                                    />
                                </Box>

                                <Box mb={2}>
                                    <Typography className="field-label">PIN</Typography>
                                    <Button variant="outlined" className="change-pin-btn" onClick={changePin}>
                                        Click here to change PIN
                                    </Button>
                                </Box>

                                <Box className="done-btn-container">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#E44E80',
                                            color: '#fff',
                                            padding: '12px 30px',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            borderRadius: '10px',
                                            width: '100%',
                                            boxShadow: 'none',
                                        }}
                                        disabled={isLoading}
                                        onClick={updateUserProfileInfo}
                                    >
                                        {isLoading ? 'Updating...' : 'Update'}
                                    </Button>
                                </Box>
                            </Paper>

                            {/* Conditionally render the alert at the bottom for non-mobile view */}
                            {!isMobile && (
                                <Alert
                                    severity="warning"
                                    sx={{
                                        backgroundColor: '#FBF8E2',
                                        height: 'fit-content',
                                        border: '2px solid #FFF5AC',
                                        width: '300px',
                                    }}
                                >
                                    To be eligible for cancer cover please enter your CNIC details. Accurate information is crucial for appropriate treatment cover.
                                </Alert>
                            )}
                            {showPinChangeModal && (
                                <Backdrop
                                    open={showPinChangeModal}
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                        zIndex: 1200,
                                        width: '100vw',  // Full width
                                        height: '100vh',  // Full height
                                        position: 'fixed', // Ensure it covers the whole screen
                                        top: 0,
                                        left: 0,
                                    }}
                                >
                                    <ChangePincodeModal
                                        wrapperClass={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                            ...(isMobile ? {
                                                width: '80%',
                                                height: 'auto',
                                                padding: '16px',
                                            } : {
                                                height: '100%',
                                                width: '100%',

                                            }),
                                        }}
                                        onContinue={handleClosePinCodeModal}
                                    />
                                </Backdrop>
                            )}
                        </Box>
                    </Box>
                </LocalizationProvider>
            )}
        </>
    );
};

export default ProfilePage;
