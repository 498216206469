import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, IconButton } from '@mui/material';
import { VpnKey, Lock, LockOpen } from '@mui/icons-material';
import './Users.scss';
import { useToast } from '../../../components/ToastNotification/ToastNotification';

const initialData = [
  { id: 1, name: 'Test User', cnic: '33102-5587478-8', dob: '20/01/1998', email: 'testing123@gmail.com', dateCreated: '04-Sept-2024 4:25am', phoneNumber: '0300-1234568', accountStatus: 'not-active', isLocked: true, planSubscribedDate: '04-Sept-2024 4:25am' },
  { id: 2, name: 'Second Test User', cnic: '33102-5587478-8', dob: '20/01/1998', email: 'testing1234@gmail.com', dateCreated: '04-Sept-2024 4:25am', phoneNumber: '0300-12345678', isLocked: false, planSubscribedDate: '04-Sept-2024 4:25am', accountStatus: 'active' },
  { id: 3, name: 'Third Test User', cnic: '33102-5587478-8', dob: '20/01/1998', email: 'testing12345@gmail.com', dateCreated: '04-Sept-2024 4:25am', phoneNumber: '0300-1234568', isLocked: true, planSubscribedDate: '04-Sept-2024 4:25am', accountStatus: 'active' },
  // Add more data as needed
];

function Users() {
  const [users, setUsers] = useState(initialData);
  const showToast = useToast();

  const resetPincode = () => {
    showToast('Pincode reset successfully', 3000);
  };

  const unlockAccount = (row) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === row.id ? { ...user, isLocked: !user.isLocked } : user
      )
    );
    showToast(row.isLocked ? 'Account unlocked successfully' : 'Account locked successfully', 3000);
  };
  
  return (
    <div className="table-wrapper">
      <div className="policy-header">
        <h2 className="header-title">Users</h2>
        <div className="button-group"></div>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell className='table-cell'>Name</TableCell>
              <TableCell className='table-cell'>Phone Number</TableCell>
              <TableCell className='table-cell'>CNIC</TableCell>
              <TableCell className='table-cell'>DOB</TableCell>
              <TableCell className='table-cell'>Email</TableCell>
              <TableCell className='table-cell'>Date Created</TableCell>
              <TableCell className='table-cell'>Account Status</TableCell>
              <TableCell className='table-cell'>Plan Subscribed Date</TableCell>
              <TableCell className='table-cell'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow key={row.id} className="table-row">
                <TableCell className='table-cell'>{row.name}</TableCell>
                <TableCell className='table-cell'>{row.phoneNumber}</TableCell>
                <TableCell className='table-cell'>{row.cnic}</TableCell>
                <TableCell className='table-cell'>{row.dob}</TableCell>
                <TableCell className='table-cell'>{row.email}</TableCell>
                <TableCell className='table-cell'>{row.dateCreated}</TableCell>
                <TableCell className='table-cell'>{row.accountStatus}</TableCell>
                <TableCell className='table-cell'>{row.planSubscribedDate}</TableCell>
                <TableCell className='table-cell'>
                  <Tooltip title="Reset Pincode">
                    <IconButton onClick={resetPincode}>
                      <VpnKey />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={row.isLocked ? "Unlock Account" : "Lock Account"}>
                    <IconButton onClick={()=>unlockAccount(row)}>
                      {row.isLocked ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Users;
