import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from './pages/Signup/Signup';
import './App.scss'
import PageTemplate from './pages/PageTemplate/PageTemplate';
import ModalContainer from './pages/Modal/Modal';
import Payment from './pages/Payment/payment';
import Dashboard from './components/Dashboard/dashboard';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import MobileContributionComponent from './components/PolicyCover/mobileContributionComponent';
import EnterDetails from './pages/ProfilePage/enterDetails';
import ReviewDetails from './pages/ReviewDetails/ReviewDetails';
import UserPlans from './components/UserPlans/userPlans';
import Policy from './pages/Admin/Policy/Policy';
import ReferralCode from './pages/Admin/ReferralCode/ReferralCode';
import Users from './pages/Admin/Users/Users';
import PlanContext from './contexts/PlanContext';
import NotFound from './components/404-NotFound/NotFound';
/* import NewsTicker from './components/NewsTicker/NewsTicker';*/
function App() {
  const { isLoggedIn, setIsLoggedIn } = useContext(PlanContext);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn, token]);
  return (
    <Router>
      <Routes>
        <Route path="/" element={isLoggedIn ? <PageTemplate><Dashboard /></PageTemplate> : <SignUp />} />
        <Route path="/contribution" element={<MobileContributionComponent />} />
        <Route path="/modal" element={<ModalContainer />} />
        <Route path="/dashboard" element={<PageTemplate><Dashboard /></PageTemplate>} />
        <Route path="/user-plans" element={<PageTemplate><UserPlans /></PageTemplate>} />
        <Route path="/profile" element={<PageTemplate><ProfilePage /></PageTemplate>} />
        <Route path="/coverage-type" element={<PageTemplate><Policy /></PageTemplate>} />
        <Route path="/referral-codes" element={<PageTemplate><ReferralCode /></PageTemplate>} />
        <Route path="/users" element={<PageTemplate><Users /></PageTemplate>} />
        <Route path="/details" element={<PageTemplate><EnterDetails /></PageTemplate>} />
        <Route path="/payment" element={<PageTemplate><Payment /></PageTemplate>} />
        <Route path="/payment/return/jazzcash" element={<PageTemplate><ReviewDetails /></PageTemplate>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
