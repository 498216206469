import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerExist: null,
  userLoginData: null,
  userSignUpData: null,
  allPlansData: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    callLogin: (_state, action) => {
      return action.payload.query;
    },
    loginData: (state, action) => {
      state.userLoginData = action.payload;
    },
    callSignUp: (_state, action) => {
      return action.payload.query;
    },
    signUpData: (state, action) => {
      state.userSignUpData = action.payload;
    },
    callValidateUser: (_state, action) => {
      return action.payload.query;
    },
    GetPlans: (_state, action) => {
    },
    AllPlansData: (state, action) => {
      state.allPlansData = action.payload;
    },
    updateUser: (_state, action) => {
      return action.payload.query;
    },
    updateContact: (_state, action) => {
      return action.payload.query;
    },
    changePincode: (_state, action) => {
      return action.payload.query;
    },
    createPlanForPolicy: (_state, action) => {
      return action.payload.query;
    },
    getUser: (_state, action) => {
      return action.payload.query;
    },
    createPolicyCover: (_state, action) => {
      return action.payload.query;
    },
    updateUserPolicy: (_state, action) => {
      return action.payload.query;
    },
    deleteUserPolicy: (_state, action) => {
      return action.payload.query;
    },
    updateUserPlan: (_state, action) => {
      return action.payload.query;
    },
    deleteUserPlan: (_state, action) => {
      return action.payload.query;
    },
    clearStates: (state) => {
      state.customerExist = null;
      state.userLoginData = null;
      state.userSignUpData = null;
      state.allPlansData = null;
    },
    prepareUserPayment: (_state, action) => {
      return action.payload.query;
    },
    
  },
});

export const {
  callLogin,
  updateUserPlan,
  prepareUserPayment,
  loginData,
  createPlanForPolicy,
  deleteUserPolicy,
  createPolicyCover,
  updateUserPolicy,
  clearStates,
  callSignUp,
  getUser,
  signUpData,
  callValidateUser,
  GetPlans,
  AllPlansData,
  updateUser,
  updateContact,
  changePincode,
  createPlanForPoliy,
  deleteUserPlan,
} = dataSlice.actions;
export default dataSlice.reducer;
