import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, useMediaQuery, TableRow, Paper, Backdrop } from '@mui/material';
import './ReferralCode.scss'
import ReferralPopup from './Popup/Popup';
const data = [
  { id: '1234', remark: 'Successful referral' },
  { id: '5678', remark: 'Pending verification' },
  { id: '9101', remark: 'Referral expired' },
  // Add more data as needed
];

function ReferralCode() {
  const [open, setOpen] = React.useState(false);
  function openReferralPopup() {
    setOpen(true)
  }
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div style={{ padding: 20 }}>
      <div className="policy-header">
        <h2 className="header-title">Referral Codes</h2>
        <div className="button-group">
          <button className="policy-button" onClick={openReferralPopup}>Create Referral</button>
        </div>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell className='width--30 table-cell'>Referral Number</TableCell>
              <TableCell className='table-cell'>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id} className="table-row">
                <TableCell className='table-cell'>{row.id}</TableCell>
                <TableCell className='table-cell'>{row.remark}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {open && <Backdrop
        open={open}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1200,
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      ><ReferralPopup wrapperClass={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        ...(isMobile
          ? { width: '80%', height: 'auto', padding: '16px' }
          : { height: '100%', width: '100%' }),
      }} onContinue={() => setOpen(false)} /></Backdrop>}
    </div>


  );
}

export default ReferralCode;
