import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import store from "./store";
import { ToastProvider } from "./components/ToastNotification/ToastNotification.jsx";
import { ShowSuccessModalProvider } from './contexts/showSuccessModal';
import { ProfileDropdownProvider } from './contexts/profileDropDown';
import { PlanProvider } from './contexts/PlanContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <ShowSuccessModalProvider>
          <ProfileDropdownProvider>
            <PlanProvider>
              <App />
            </PlanProvider>
          </ProfileDropdownProvider>
        </ShowSuccessModalProvider>
      </ToastProvider>
    </Provider>
  </React.StrictMode>
);

