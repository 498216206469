import React, { useState } from 'react';
import { Modal, Box, Typography, Radio, RadioGroup, FormControlLabel, Button } from '@mui/material';
import './unsubscribeModal.scss';

const UnsubscribeModal = ({ open, onClose, onConfirm }) => {
    const [selectedReason, setSelectedReason] = useState('');

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleContinueUnsubscription = () => {
        onConfirm();
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="unsubscribe-modal">
            <Box className="modal-overlay">
                <Box className="unsubscribe-modal-box">
                    <Typography variant="h5" className="modal-title">Unsubscribe</Typography>
                    <Typography variant="body1" className="modal-subtitle">
                        Are you sure you want to unsubscribe? Please let us know the reason to unsubscribe, so we can improve our program.
                    </Typography>
                    <RadioGroup value={selectedReason} onChange={handleReasonChange}>
                        <FormControlLabel value="too_expensive" control={<Radio />} label="Too expensive" />
                        <FormControlLabel value="no_need" control={<Radio />} label="No longer need the membership" />
                        <FormControlLabel value="changing_bank" control={<Radio />} label="I’m changing my bank" />
                        <FormControlLabel value="not_satisfied" control={<Radio />} label="Not satisfied with program dynamics" />
                        <FormControlLabel value="mistake" control={<Radio />} label="Had subscribed by mistake" />
                    </RadioGroup>

                    <Button variant="outlined" onClick={onClose} className="continue-button">
                        Continue Subscription
                    </Button>
                    <Button variant="contained" color="error" onClick={handleContinueUnsubscription} className="confirm-button">
                        Confirm Cancel Subscription
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UnsubscribeModal;
