import React, { useState, useEffect } from 'react';
import '../../Policy/PolicyPopup/Popup.scss';
import TitleAndLogo from '../../../../components/TitleAndLogo/titleAndLogo';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { createPolicyCover, updateUserPolicy } from '../../../../features/api.slice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../store';

const ReferralPopup = ({ onContinue, wrapperClass, tabForEdit }) => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const showToast = useToast()
    const [referralCode, setReferralCode] = useState('')
    const [remarks, setRemarks] = useState('')
    const navigate = useNavigate()
    function createPlan() {
        setIsLoading(true)
        const payload = {
            title: referralCode,
            description: remarks,
            navigate,
            showToast,
            setIsLoading,
            onContinue,
        }
        dispatch(createPolicyCover(payload))
    }
    function updatePlan() {
        setIsLoading(true)
        const policyID = tabForEdit.id
        const payload = {
            title: referralCode,
            description: remarks,
            showToast,
            policyID,
            setIsLoading,
            onContinue,
        }
        dispatch(updateUserPolicy(payload, tabForEdit.id))
    }
    function onClose() {
        onContinue()
        setReferralCode('')
        setRemarks('')
    }
    useEffect(() => {
        if (tabForEdit !== null && tabForEdit !== undefined) {
            setReferralCode(tabForEdit.title)
            setRemarks(tabForEdit.description)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="title-logo-container">
                    <TitleAndLogo logoColor="pink" />
                    <IconButton
                        className="close-button"
                        onClick={onClose} // Call the onClose function
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Referral Code"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="Remarks"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                />
                <button className={isLoading ? 'disabled-button continue-button' : 'continue-button'} disabled={isLoading} onClick={tabForEdit ? updatePlan : createPlan}>{tabForEdit && isLoading ? 'Updating....' : tabForEdit && !isLoading ? 'Update' : !tabForEdit && isLoading ? 'Creating' : 'Create'}</button>
            </div>
        </div>
    );
};

export default ReferralPopup;
