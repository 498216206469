import React from "react";
import "./NewsPopup.scss"; // Import the CSS file

const NewsPopup = ({ newsText, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
       {/*  <button className="close-button" onClick={onClose}>
          &times;
        </button> */}
        <p>{newsText}</p>
        <button className="action-button" onClick={onClose}>Continue to Login</button>
      </div>
    </div>
  );
};

export default NewsPopup;
