import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import './leftnav.scss';

const LeftNavMenu = ({ onPageChange, isMobileNavOpen, toggleMobileNav }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const userData = JSON.parse(localStorage.getItem('user'));

    // State to track the selected page
    const [selectedPage, setSelectedPage] = useState('dashboard');

    // Handle button click and set selected page
    const handlePageChange = (page) => {
        setSelectedPage(page);
        onPageChange(page); // Call the parent function to change the page
        if (!isDesktop) {
            toggleMobileNav(); // Close drawer on mobile after selection
        }
    };
    const drawerContent = (
        <List>
            <ListItem
                button="true"
                onClick={() => handlePageChange('dashboard')}
                className={`nav-item ${selectedPage === 'dashboard' ? 'active' : ''}`}
            >
                <ListItemText primary="Dashboard" />
            </ListItem>
            {userData?.admin &&
                <>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('users')}
                        className={`nav-item ${selectedPage === 'users' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('referral-codes')}
                        className={`nav-item ${selectedPage === 'referral-codes' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Referral Codes" />
                    </ListItem>
                    <ListItem
                        button="true"
                        onClick={() => handlePageChange('coverage-type')}
                        className={`nav-item ${selectedPage === 'coverage-type' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Coverage Type" />
                    </ListItem>
                    {/* <ListItem
                        button="true"
                        onClick={() => handlePageChange('reports')}
                        className={`nav-item ${selectedPage === 'reports' ? 'active' : ''}`}
                    >
                        <ListItemText primary="Reports" />
                    </ListItem> */}
                </>
            }
        </List>
    );

    return (
        <div className="left-nav-container">
            <Toolbar />
            <Drawer
                variant={isDesktop ? 'permanent' : 'temporary'}
                className={isDesktop ? "permanent-drawer" : ''}
                classes={{ paper: 'left-nav-drawer' }}
                open={isDesktop || isMobileNavOpen}
                onClose={toggleMobileNav} // Close the drawer when clicking outside on mobile
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile
                }}
            >
                {drawerContent}
            </Drawer>
        </div>
    );
};

export default LeftNavMenu;
