import React, { useContext,useEffect,useRef } from 'react';
import PlanContext from '../../contexts/PlanContext';
import './planSelector.scss'
import { useToast } from '../ToastNotification/ToastNotification';

const PlanSelector = ({ handleOpenModal, selectedPolicy }) => {
    const { planName, setPlanName } = useContext(PlanContext);
    const policyData = selectedPolicy?.selectedPolicy?.selectedPolicy?.plans
    const showToast = useToast()
    const images = [
        { name: 'Bronze', price: 20, image: '/images/dashboard/bronze.png' },
        { name: 'Silver', price: 50, image: '/images/dashboard/silver.png', recommended: true },
        { name: 'Gold', price: 100, image: '/images/dashboard/gold.png' },
        { name: 'Platinum', price: 1000, image: '/images/dashboard/platinum.png' },
    ]
    const policyWithImages = policyData?.map((plan) => {
        const image = images.find((image) => image.name === plan.title);
        return {
            ...plan,
            image: image.image,
        };
    });
    const recommendPlan = policyWithImages?.find((plan) => plan.recommended === 1);
    const selectedPlan = policyWithImages?.find((plan) => plan.title === planName);
    function onClickContinueCreation() {
        if (!selectedPlan) {
            showToast('Please select a plan', 3000)
        }
        if (selectedPlan) {
            handleOpenModal(selectedPlan)
        }
    }
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender?.current && recommendPlan) {
            setPlanName(recommendPlan?.title);
            initialRender.current = false; // Mark as not the initial render anymore
        }
    }, [recommendPlan, setPlanName]);
    return (
        <div className="plans">
            {policyWithImages?.map((plan) => (
                <label
                    key={plan.title}
                    className={`plan ${planName === plan.title ? 'selected' : ''}`}
                >
                    <img src={plan.image} alt={plan.title} className="plan-image" />
                    <div className="plan-info">
                        <span>{plan.title}</span>
                        <span>{plan.price} Rs/month</span>
                    </div>
                    <input
                        type="radio"
                        name="plan"
                        value={plan.title}
                        checked={planName === plan.title}
                        onChange={() => setPlanName(plan.title)}
                    />
                    {plan.recommended === 1 && <span className="recommended">Recommended</span>}
                </label>
            ))}
            {policyWithImages?.length > 0 ?
                <button className="continue-button" onClick={onClickContinueCreation}>
                    Continue {planName && <span>with {planName}</span>}
                </button>
                : ''}
        </div>
    );
};

export default PlanSelector;
